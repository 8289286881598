import React, { useState } from "react";
import Toggle from "react-toggle";
import UploadIcon from "assets/img/cloud_upload.png";
import "./styles.scss";

const LogoUpload = ({ handleChange, logoPath, dropzone, organizationLogo }) => {
  const [showUrl, setShowUrl] = useState(false);
  const { getRootProps, getInputProps } = dropzone;

  const handleClick = () => {
    setShowUrl(!showUrl);
  };

  return (
    <div className="LogoUpload-container">
      <div className="toggle-wrap d-flex flex-row my-5">
        <Toggle checked={!showUrl} icons={false} onChange={handleClick} />
        <h4 className="m-0 ml-5">Would you like to upload a logo??</h4>
      </div>
      {!showUrl ? (
        <div {...getRootProps()} className="LogoUpload-dropzone">
          <label>Click or drag an image to upload logo..</label>
          <input {...getInputProps()} accept="image/*" />
          {organizationLogo ? (
            <img
              style={{ height: 100 }}
              src={organizationLogo.base64String}
              alt="Upload Campaign Logo"
            />
          ) : (
            <img
              style={{ height: 100 }}
              src={UploadIcon}
              alt="Upload Campaign Logo"
            />
          )}
        </div>
      ) : (
        <>
          <label>Add a sender logo url.</label>
          <input
            type="text"
            name="sender_logo_path"
            className="form-control mt-3"
            placeholder="e.g. https://example.com/your-logo.png"
            value={logoPath}
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
};

export default LogoUpload;
