import React from "react";
import StepLayout from "layouts/Step";
import Toggle from "react-toggle";
import Select from "react-select";
import "./styles.scss";
import { USAStates } from "services/util.js";
import Modal from "components/Modal/Modal";
import { customStyles } from "./styles.js";
import imgScanner from "assets/img/ico_scanner.svg";
import LogoUpload from "components/LogoUpload";
import useStep from "hooks/steps";
import useWhoStep from "./hooks";

const WhoStep = props => {
  const { error, send, loading, setError, step } = useStep();
  const {
    org,
    digitized,
    setDigitized,
    modal,
    toggleModal,
    handleChange,
    toggleOrg,
    disabled,
    organization_logo,
    values,
    handleChangeState,
    patchCampaign,
    dropzone
  } = useWhoStep(send, setError);

  return (
    <StepLayout
      {...props}
      step={step}
      loading={loading && !send}
      disabled={disabled}
      nextAction={patchCampaign}
      error={error}
      title="Send - Who should we say is sending?"
      nextLink={`who/options`}
      previousLink={`how/options`}
    >
      <h1 className="mt-5">Who should we say is sending?</h1>
      <div className="row mx-0 justify-content-center align-items-center mt-5">
        <div className="col-sm-7 WhoStep-toggles">
          {!org ? (
            <>
              <input
                type="text"
                name="firstName"
                className="form-control mt-3"
                placeholder="First Name"
                value={values.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lastName"
                className="form-control mt-3"
                placeholder="Last Name"
                value={values.lastName}
                onChange={handleChange}
              />
            </>
          ) : (
            <input
              type="text"
              name="fromOrganizationName"
              className="form-control mt-3"
              placeholder="Organization Name"
              value={values.fromOrganizationName}
              onChange={handleChange}
            />
          )}

          <div className="toggle-wrap d-flex flex-row my-5">
            <Toggle checked={org} icons={false} onChange={toggleOrg} />
            <h4 className="m-0 ml-5">Sending as organization?</h4>
          </div>

          <div className="toggle-wrap d-flex flex-row my-5">
            <Toggle
              defaultChecked={digitized}
              icons={false}
              onChange={() => setDigitized(!digitized)}
            />
            <h4 className="m-0">Would you like your mail returns digitized?</h4>
            <button
              className="btn btn-sm btn-outline-primary nb"
              onClick={toggleModal}
            >
              {" "}
              ?{" "}
            </button>
          </div>

          {!digitized && (
            <>
              <input
                type="text"
                name="address1"
                className="form-control mt-3"
                placeholder="Address"
                value={values.address1}
                onChange={handleChange}
              />
              <div className="form-row">
                <div className="col-sm-5">
                  <input
                    type="text"
                    name="city"
                    className="form-control mt-3"
                    placeholder="City"
                    value={values.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-sm-3 d-flex align-items-end state-select">
                  <Select
                    options={USAStates}
                    value={USAStates.filter(st => st.value === values.state)}
                    styles={customStyles}
                    placeholder="State"
                    onChange={value => handleChangeState(value)}
                  />
                </div>
                <div className="col-sm-4">
                  <input
                    type="number"
                    name="zip"
                    className="form-control mt-3"
                    placeholder="Zip"
                    value={values.zip}
                    maxLength="5"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </>
          )}
          <section>
            <LogoUpload
              handleChange={handleChange}
              logoPath={values.logoPath}
              organizationLogo={organization_logo}
              dropzone={dropzone}
            />
          </section>
        </div>
      </div>
      {modal && (
        <Modal
          img={imgScanner}
          title="Digitized Returns"
          body="Digitized mail returns directs all return receipts and undeliverable or unclaimed items to the send mail house where each is scanned and associated with this send, accessible anytime with just click."
          close={toggleModal}
        />
      )}
    </StepLayout>
  );
};

export default WhoStep;
