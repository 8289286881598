import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { resizeImage } from "services/util";
import APIService from "services/backstrap/apiService";

const useWhoStep = (send, setError) => {
  const {
    from_first_name,
    from_last_name,
    from_address_line_1,
    from_city_town,
    from_state_province_district,
    from_postal_code,
    from_organization_name,
    metadata
  } = send;

  let lp = "";
  if (send.metadata && send.metadata.logo_path) {
    lp = send.metadata.logo_path;
  }
  const initialSendState = {
    firstName: from_first_name || "",
    lastName: from_last_name || "",
    address1: from_address_line_1 || "",
    city: from_city_town || "",
    state: from_state_province_district || "",
    zip: from_postal_code || "",
    fromOrganizationName: from_organization_name || "",
    logoPath: lp
  };
  const [modal, modalState] = useState(false);
  const [org, setOrg] = useState(true);
  const [digitized, setDigitized] = useState(true);
  const toggleModal = () => modalState(!modal);
  const [values, setValues] = useState(initialSendState);

  const fullName = Boolean(values.firstName || values.lastName);
  const disabled =
    values.firstName || values.fromOrganizationName ? false : true;
  const organization_logo =
    send?.metadata?.organization_logo || values.organization_logo;

  const onDrop = useCallback(
    files => {
      var file = files[0];
      const reader = new FileReader();
      reader.onload = event => {
        let frmt = event.target.result
          .substring(5, event.target.result.indexOf(";"))
          .toLowerCase();
        if (
          [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "image/bmp"
          ].includes(frmt)
        ) {
          resizeImage(file, 200)
            .then(resizedFile => {
              if (resizedFile.length < 55000) {
                var fileData = {
                  base64String: resizedFile,
                  fileExt: file.type.split("/").slice(-1)[0]
                };

                setValues(values => ({
                  ...values,
                  organization_logo: fileData
                }));
              } else {
                setError("Logo file size too large.");
              }
            })
            .catch(err => {
              console.log("err", err);
            });
        } else {
          setError("Logo file must be one of: png, jpg, gif, bmp");
        }
      };
      reader.readAsDataURL(file);
    },
    [setError]
  );

  const dropzone = useDropzone({ onDrop });

  const toggleOrg = () => {
    if (org) {
      // SWITCHING TO FIRST/LAST NAME
      // dispatch({type: 'SET_SENDER_ORG_NAME', payload: null});
      //dispatch({type: 'SET_ORGANIZATION_LOGO', payload: null})
    } else {
      // SWITCHING TO ORG NAME
      // dispatch({type: 'SET_SENDER_FIRST_NAME', payload: null});
      // dispatch({type: 'SET_SENDER_LAST_NAME', payload: null});
    }
    setOrg(!org);
  };

  const patchCampaign = async () => {
    const campaign = {
      id: send.id,
      from: {
        first_name: values.firstName,
        last_name: values.lastName,
        organization_name: values.fromOrganizationName,
        address_line_1: values.address1,
        city_town: values.city,
        state_province_district: values.state,
        postal_code: values.zip,
        country: "USA"
      },
      metadata: {
        ...send.metadata,
        logo_path: values.logoPath,
        organization_logo: values.organization_logo
      }
    };
    const fullName = values.firstName && values.lastName;
    if (fullName || values.fromOrganizationName) {
      try {
        await APIService.patchCampaign(campaign);
      } catch (err) {
        if (err.data && err.data.message) {
          setError(err.data.message);
        } else {
          setError("Failed to update this campaign");
        }
      }
    }
  };

  const handleChange = event => {
    event.persist();
    let targetName = event.target.name;
    if (event.target.name === "sender_logo_path") targetName = "logoPath";
    setValues(values => ({
      ...values,
      [targetName]: event.target.value
    }));
  };

  const handleChangeState = value => {
    setValues(values => ({
      ...values,
      state: value.value
    }));
  };

  useEffect(() => {
    const emptyName = !fullName;
    setOrg(emptyName);
    setDigitized(!values.address1);
    if (send.id) {
      setValues(initialSendState);
    }
    // eslint-disable-next-line
  }, [send.id]);

  return {
    digitized,
    setDigitized,
    modal,
    toggleModal,
    handleChange,
    org,
    toggleOrg,
    disabled,
    values,
    handleChangeState,
    patchCampaign,
    dropzone,
    organization_logo
  };
};

export default useWhoStep;
