export const environment = "dev";
let apiUrl = "http://localhost:3001";
let mailhouseUrl = "http://localhost:3001";

switch (environment) {
  case "dev":
    apiUrl = "https://dev-api.send.betatest.team";
    mailhouseUrl = "https://dev.mailhouse.betatest.team";
    break;
  case "staging":
    apiUrl = "https://stage-api.send.betatest.team";
    mailhouseUrl = "https://staging.mailhouse.betatest.team";
    break;
  case "prod":
    apiUrl = "https://api.send.betatest.team";
    mailhouseUrl = "https://mailhouse.betatest.team";
    break;
  default:
    break;
}

export const API_URL = apiUrl;
export const MAILHOUSE_URL = mailhouseUrl;

export const ENDPOINTS = {
  SIGN_IN: "/common/accounts/signIn/1.0.0?",
  GET_CAMPAIGNS: "/v1/campaign",
  GET_CAMPAIGNS_FILTERED: "/v1/campaign/filter",
  GET_CAMPAIGN_DETAILS: "/v1/campaign?id=",
  GET_CAMPAIGN_STATUS: "/v1/campaign/status/1.0.0?id=",
  GET_CAMPAIGN_TYPES: "/v1/campaign/type/1.0.0",
  GET_CAMPAIGN_DATASOURCE: "/v1/campaign/datasource?id=",
  GET_CAMPAIGN_DOCIDS: "/v1/campaign/documentIds?id=",
  GET_DOCUMENT: "/v1/document?id=",
  GET_DOCUMENT_STATUSES: "/v1/document/filter/1.0.0?campaign_id=",
  GET_DOCUMENT_FOR_CAMPAIGN: "/v1/document/1.0.0?campaign_id=",
  SEARCH_DOCUMENTS: "/v1/document/search/1.0.0",
  GET_MAIL_CLASSES: "/v1/destination/mail_class/1.0.0?id=",
  GET_PAPER_TYPES: "/v1/destination/paper_type/1.0.0?id=",
  POST_CAMPAIGN: "/v1/campaign",
  POST_FOLLOWUP_CAMPAIGN: "/v1/campaign/followup/1.0.0",
  POST_CAMPAIGN_TEMPLATE: "/v1/campaign/template/1.0.0",
  PUT_TEMPLATE: "/v1/template/index/1.0.0",
  ATTACH_TEMPLATE: "/v1/campaign/template/1.0.0",
  POST_CAMPAIGN_CSV: "/v1/datasource?use_field_map=true",
  ATTACH_DATASOURCE: "/v1/campaign/datasource/1.0.0",
  PATCH_CAMPAIGN_DETAILS: "/v1/campaign",
  SEND_CAMPAIGN: "/v1/campaign/send/1.0.0",
  GET_PREVIEWS: "/v1/campaign/previews/1.0.0?id=",
  GET_TEMPLATE: "/v1/campaign/template/1.0.0?id=",
  GET_ALL_TEMPLATES: "/v1/template/filter",
  GET_CSV: "/v1/campaign/datasource/1.0.0?id=",
  GET_DATASOURCE: "/v1/datasource?id=",
  GET_DATASOURCE_FILTERED: "/v1/datasource/filter",
  GET_DATASOURCE_FIELDMAP: "/v1/datasource-fieldmap",
  POST_DATASOURCE_FIELDMAP: "/v1/datasource-fieldmap",
  APPLY_DATASOURCE_FIELDMAP: "/v1/datasource/applyFieldMap",
  SUGGESTED_FIELDMAP: "/v1/datasource/suggestedFieldMap?id=",
  MERGE_CAMPAIGN: "/v1/campaign/merge",
  GET_SENT_CS_MAILHOUSE: "/v1/reporting/send_to_cs_mailhouse",
  POST_DOCUMENT_PREVIEW: "/v1/document/preview"
};
